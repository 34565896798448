export const locale = {
  lang: "en",
  localeData: {
    languagesetting: "Language Settings",
    editProfile: {
      edit: "Edit Profile",
    },
    login: {
      capital: "LOGIN",
      lower: "login",
      pascal: "Login",
      spaceErrorMessage:
        "You are not associated with any space, Please contact your system administrator",
      signInHeaderMessage: "Sign in failed!",
      spaceNotFound:
        "Failed to get space information, Please contact your system administrator",
      loginError: "Invalid Email or Password.",
      userNotFound: "User not found. Please login again.",
      error: {
        dismiss: "Dismiss",
        rateLimit: {
          informationMessage: "Too many users log in at the same time.",
          instructionMessage: "Please try again in a few minutes."
        },
        unregistered: {
          informationMessage: "The e-mail address is not yet registered.",
          instructionMessage: "Please ask your space owner to invite you to Space."
        },
        initialization: {
          informationMessage: "Failed to link your account to DIVA Account.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        invalidDomain: {
          informationMessage: "The e-mail address does not use external authentication.",
          instructionMessage: "Please try again with an e-mail that can use external authentication."
        },
        sessionExpired: {
          informationMessage: "Your session has expired.",
          instructionMessage: "Please log in again."
        },
        internal: {
          informationMessage: "Failed to log in due to an error.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        fallback: {
          informationMessage: "Something went wrong.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        getUserFailed: {
          informationMessage: "Failed to get user information.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        blocked: {
          informationMessage: "Your account has been blocked.",
          instructionMessage: "To unblock your account, please contact your IT support."
        }
      }
    },
    loginId: "Login ID (Email)",
    password: {
      password: "Password",
      forgot: "Forgot Password?",
      confirm: "Password (Confirm)",
      confirmIsRequired: "Password confirmation",
      match: "Password must match",
      forgetPasswordEmailMessage:
        "An mail has been sent to your id, please click on the link in the mail to reset your password!",
      forgetPasswordDialogHeading: "Password reset mail sent",
      forgetPasswordErrorMessage: "Password reset failed!",
    },
    rememberMe: "Remember Me",
    enterEmail: "Please enter a valid email address",
    logout: "Logout",
    profile: {
      my: "My Profile",
      profile: "Profile",
      edit: "Edit Profile",
    },
    menu: "menu",
    "translate-en": "Translate to English",
    "translate-ja": "Translate to Japanese",
    resetPassword: {
      capital: "RECOVER YOUR PASSWORD",
      lower: "recover your password",
      pascal: "Recover your password",
    },
    resetLink: {
      capital: "SEND RESET LINK",
      lower: "send reset link",
      pascal: "Send reset link",
    },
    dashboard: {
      confirmMessage: "Do you want to switch space?",
      confirmDialogTitle: "Switch Space?",
    },
    portal: {
      open: "OPEN",
      download: "Download",
      servicesTitle: "DIVA Cloud Services",
      greeting: "Hello!",
      settings: {
        title: "Settings",
        about: "About DIVA Cloud",
        oss: "OSS License",
        ossDescription:
          "List of Open Source Licenses used on DIVA Cloud Services",
        view: "View",
      },
      san: "",
    },
    "no-permission": {
      accessMessage:
        "You don't have permission to access <b>[spaceName]</b>.",
      spaceSelectMessage: "Please select other space from space selector.",
      noPermissionMessage: "You don't have permission to access this space.",
    },
  },
};
