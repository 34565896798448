export const locale = {
  lang: "ja",
  localeData: {
    languagesetting: "言語設定",
    editProfile: {
      edit: "プロフィール編集",
    },
    login: {
      capital: "ログイン",
      lower: "ログイン",
      pascal: "ログイン",
      spaceErrorMessage:
        "利用できるスペースがありません。管理者にお問い合わせください。",
      signInHeaderMessage: "ログインに失敗しました。",
      spaceNotFound:
        "スペース情報が取得できませんでした。管理者にお問い合わせください。",
      loginError: "ユーザー名またはパスワードが間違っています。",
      userNotFound: "ユーザーが見つかりませんでした。もう一度ログインしてください。",
      error: {
        dismiss: "閉じる",
        rateLimit: {
          informationMessage: "多くのユーザーが同時にログインしました。",
          instructionMessage: "数分後にもう一度お試しください。",
        },
        unregistered: {
          informationMessage: "メールアドレスが未登録です。",
          instructionMessage: "スペースオーナーにスペースへの招待を依頼してください。",
        },
        initialization: {
          informationMessage: "お客様のアカウントでDIVAアカウントへのリンクを失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。",
        },
        invalidDomain: {
          informationMessage: "このメールアドレスは外部認証を利用できません。",
          instructionMessage: "外部認証を利用できるメールアドレスで再度お試しください。"
        },
        sessionExpired: {
          informationMessage: "セッションの有効期限が切れました。",
          instructionMessage: "再度ログインしてください。"
        },
        internal: {
          informationMessage: "エラーによりログインに失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        fallback: {
          informationMessage: "エラーが発生しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        getUserFailed: {
          informationMessage: "ユーザー情報の取得に失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        blocked: {
          informationMessage: "このアカウントはブロックされています。",
          instructionMessage: "アカウントのブロックを解除するには、ITサポートにお問い合わせください。"
        }
      },
    },
    loginId: "ログインID（電子メール）",
    password: {
      password: "パスワード",
      forgot: "パスワードをお忘れですか？",
      confirm: "パスワード（確認）",
      confirmIsRequired: "パスワードの確認",
      match: "パスワードが一致している必要があります",
      forgetPasswordEmailMessage:
        "メールを送信しました。メール内のリンクをクリックしてパスワードを再設定してください。",
      forgetPasswordDialogHeading: "パスワードリセットメールを送信しました",
      forgetPasswordErrorMessage: "パスワードリセットに失敗しました",
    },
    rememberMe: "ログイン情報を保存する",
    enterEmail: "有効なメールアドレスを入力してください",
    logout: "ログアウト",
    profile: {
      my: "私のプロフィール",
      profile: "プロフィール",
      edit: "プロフィール編集",
    },
    menu: "メニュー",
    "translate-en": "Translate to English",
    "translate-ja": "Translate to Japanese",
    resetPassword: {
      capital: "パスワードリセット",
      lower: "パスワードリセット",
      pascal: "パスワードリセット",
    },
    resetLink: {
      capital: "メールを送信する",
      lower: "メールを送信する",
      pascal: "メールを送信する",
    },
    dashboard: {
      confirmMessage: "Spaceを切り替えますか？",
      confirmDialogTitle: "Space切り替え",
    },
    portal: {
      open: "開く",
      download: "ダウンロード",
      servicesTitle: "DIVA Cloud Services",
      greeting: "こんにちは！",
      settings: {
        title: "設定",
        about: "About DIVA Cloud",
        oss: "OSS License",
        ossDescription:
          "List of Open Source Licenses used on DIVA Cloud Services",
        view: "View",
      },
      san: "さん",
    },
    "no-permission": {
      accessMessage:
        "Space <b>[spaceName]</b> にアクセスできません",
      spaceSelectMessage: "Spaceセレクターから、Spaceを選択してください",
      noPermissionMessage: "Spaceにアクセスできません",
    },
  },
};
