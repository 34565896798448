import { Component, OnInit, OnDestroy, ComponentRef } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Banner, BannerComponent, BannerService, BannerType } from "diva-lib";
import { ErrorCodes } from "../errorcodes";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject<any>();
  public isLoading: boolean;
  private bannerComponentRef: ComponentRef<BannerComponent>;
  bannerContainerId: string = "#diva-banner-container";

  constructor(
    public authService: AuthService,
    public router: Router,
    public translateService: TranslateService,
    private bannerService: BannerService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.authService.isAuthenticated$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((loggedIn) => {
        // check error code in the query parameter from login.callback.component
        // example of query: http://---/index?errorcode=9000
        let queryParams = this.activatedRoute.snapshot.queryParams;
        let errorCode = queryParams["errorcode"];

        if (loggedIn && !errorCode) {
          this.router.navigate(["dashboards/portal"]);
        } else {
          document.getElementById("global-spinner").style.display = "none";

          if (errorCode) {
            // set error code and set Banner to display the message area
            this.showErrorBanner(queryParams["errorcode"]);
          }
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.unsubscribe();
  }

  login(redirectPath: string = "dashboards") {
    this.authService.loginWithRedirect({
      appState: { target: redirectPath },
      authorizationParams: {
        /* eslint-disable camelcase */
        redirect_uri: `${window.location.origin }/logincallback`,
        ui_locales: localStorage.getItem("localStoreLang"),
        /* eslint-enable camelcase */
      }
    });
  }

  showErrorBanner(errorcode: string) {
    let banner: Banner = {
      type: BannerType.Error,
      informationMessage: "",
      instructionMessage: "",
      actionButtonText: this.translateService.stream("login.error.dismiss"),
    };

    switch (errorcode) {
    case ErrorCodes.TOOMANYLOGIN: {
      banner.informationMessage = this.translateService.stream(
        "login.error.rateLimit.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.rateLimit.instructionMessage"
      );
      break;
    }
    case ErrorCodes.NOEMAIL: {
      banner.informationMessage = this.translateService.stream(
        "login.error.unregistered.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.unregistered.instructionMessage"
      );
      break;
    }
    case ErrorCodes.ACCOUNTLINKFAILED: {
      banner.informationMessage = this.translateService.stream(
        "login.error.initialization.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.initialization.instructionMessage"
      );
      break;
    }
    case ErrorCodes.INVALIDDOMAIN: {
      banner.informationMessage = this.translateService.stream(
        "login.error.invalidDomain.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.invalidDomain.instructionMessage"
      );
      break;
    }
    case ErrorCodes.SESSIONEXPIRED: {
      banner.informationMessage = this.translateService.stream(
        "login.error.sessionExpired.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.sessionExpired.instructionMessage"
      );
      break;
    }
    case ErrorCodes.ELSE: {
      banner.informationMessage = this.translateService.stream(
        "login.error.internal.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.internal.instructionMessage"
      );
      break;
    }
    case ErrorCodes.GETUSERFAILED: {
      banner.informationMessage = this.translateService.stream(
        "login.error.getUserFailed.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.getUserFailed.instructionMessage"
      );
      break;
    }
    case ErrorCodes.BLOCKED: {
      banner.informationMessage = this.translateService.stream(
        "login.error.blocked.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.blocked.instructionMessage"
      );
      break;
    }
    default: {
      banner.informationMessage = this.translateService.stream(
        "login.error.fallback.informationMessage"
      );
      banner.instructionMessage = this.translateService.stream(
        "login.error.fallback.instructionMessage"
      );
      break;
    }
    }

    this.bannerComponentRef = this.bannerService.open(
      banner,
      this.bannerContainerId
    );
  }
}
