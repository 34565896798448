import { languages } from "@shared/const/lang";
import { AppConfig } from "@app/config/app.config";

export class SharedSettings {
  public static rewriteUrl(url: string): string {
    return url;
  }

  public static get defaultLanguage(): string {
    return "en";
  }

  public static get getLanguagesIdInArray(): Array<string> {
    return languages.map((language) => language.id);
  }

  // --------------individual service urls --------------------------//
  public static get accountServiceUrl(): any {
    return `${AppConfig.settings.accountServiceUrl}`;
  }

  public static get portalBackendUrl(): any {
    return `${AppConfig.settings.portalBackendUrl}`;
  }

  public static get editProfileUrl(): any {
    return `${AppConfig.settings.editProfileUrl}`;
  }

  public static get domain(): any {
    return `${AppConfig.settings.domain}`;
  }
  public static get clientId(): any {
    return `${AppConfig.settings.clientId}`;
  }
  public static get audience(): any {
    return `${AppConfig.settings.audience}`;
  }
}
