import { NgModule } from "@angular/core";
import { ModuleWithProviders, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { LoginComponent } from "./auth/login/login.component";
import { LoginCallbackComponent } from "./auth/logincallback/login.callback.component";
import { MatIconModule } from "@angular/material/icon";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";
import { AlertService } from "diva-lib";

@NgModule({
  declarations: [LoginComponent, LoginCallbackComponent],
  imports: [MatIconModule, MatSnackBarModule, CommonModule, SharedModule, TranslateModule.forChild(),],
  exports: [MatIconModule, SharedModule],
  providers: [AlertService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
