import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TranslationLoaderService } from "./shared/services/translation-loader.service";
import { locale as translateEnglish } from "../assets/i18n/en";
import { locale as translateJapanese } from "../assets/i18n/ja";
import { SharedSettings } from "./shared/setting/shared-settings";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "./shared/services/global.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    private translationLoaderService: TranslationLoaderService,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) {
    const localStoreLang: string = localStorage.getItem("localStoreLang");
    const browserLang: string = this.translate.getBrowserLang();
    const availableLanguages = SharedSettings.getLanguagesIdInArray;

    this.translate.addLangs(availableLanguages);

    // set default language for app
    this.translate.setDefaultLang(
      localStoreLang && this.isAvailableLanguage(availableLanguages, localStoreLang)
        ? localStoreLang
        : browserLang && this.isAvailableLanguage(availableLanguages, browserLang)
          ? browserLang
          : SharedSettings.defaultLanguage
    );

    // Setting the default language in local storage
    localStorage.setItem("localStoreLang", this.translate.getDefaultLang());

    // Set the navigation translations
    this.translationLoaderService.loadTranslations(
      translateEnglish,
      translateJapanese
    );
  }

  /**
  * Check whether if a given languageId is one of available languages
  *
  * @param languageId
  */
  private isAvailableLanguage(availableLanguages: string[], languageId: string): boolean {
    if (languageId.match(availableLanguages.join("|"))) {
      return true;
    }

    return false;
  }

  title = "DIVA Portal";

  ngOnInit(): void {
    this.handleQueryParam();
  }

  handleQueryParam() {
    this.route.queryParams.subscribe(params => {
      const spaceId = params.space;

      if (spaceId !== undefined && spaceId !== this.globalService.currentSpaceId) {
        this.globalService.changeSpace(params.space);
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void { }
}
