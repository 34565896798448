import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { AuthService } from "@auth0/auth0-angular";
import { ErrorCodes } from "@app/core/auth/errorcodes";
import { Router } from "@angular/router";

export const unauthorizedStatusCode = 401;
export const noPermissionStatusCode = 403;

@Injectable({
  providedIn: "root",
})
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has("NoAuthValidate")) {
      return this.auth.getAccessTokenSilently().pipe(
        mergeMap((token) => {
          let space = sessionStorage.getItem("userSpaceID");

          if (!space) {
            space = "";
          }

          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}`, Space: space },
          });

          return next.handle(tokenReq);
        }),
        catchError((error) => {
          // if it is error 401,
          // force user to be logged out and redirect user to login page
          if(error.status === unauthorizedStatusCode) {
            this.auth.logout({
              logoutParams: {
                returnTo:
              `${document.location.origin
              }/index?errorcode=${
                ErrorCodes.SESSIONEXPIRED}`,
              }
            });
          }

          // if it is error 403,
          // user will be directed to page "/permission/no-access"
          if(error.status !== noPermissionStatusCode && error.status !== unauthorizedStatusCode) {
            // Display index screen and fallback error message
            this.router.navigate(["index"], { queryParams: { errorcode: ErrorCodes.FALLBACK } });
          }

          return throwError(() => error);
        })
      );
    }

    return next.handle(req);
  }
}
