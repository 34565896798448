import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import { AppConfig } from "./config/app.config";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
import { DivaLibModule } from "diva-lib";
import { UnauthorizedRequestInterceptor } from "./shared/http-interceptor.service";
import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from "@angular/cdk/overlay";
import { CoreModule } from "./core/core.module";
import { MatButtonModule } from "@angular/material/button";
import { AuthConfigService, AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { SharedSettings } from "./shared/setting/shared-settings";

export let initializeApp = (appConfig: AppConfig) => {
  return () => appConfig.load();
};

export let initializeSpace = () => {
  // set userSpaceID value in localStorage (last selected space ID) to sessionStorage
  return () => {
    return new Promise<void>((resolve) => {
      if (!sessionStorage.getItem("userSpaceID")) {
        sessionStorage.setItem("userSpaceID", localStorage.getItem("userSpaceID"));
      }
      resolve();
    });
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    CoreModule.forRoot(),
    BrowserAnimationsModule,
    MatButtonModule,
    SharedModule,
    DivaLibModule,
    AuthModule.forRoot(),
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSpace,
      deps: [],
      multi: true,
    },
    {
      provide: AuthConfigService,
      useFactory: () => ({
        domain: SharedSettings.domain,
        clientId: SharedSettings.clientId,
        authorizationParams: {
          /* eslint-disable camelcase */
          redirect_uri: `${window.location.origin }/logincallback`,
          ui_locales: localStorage.getItem("localStoreLang"),
          /* eslint-enable camelcase */
          audience: SharedSettings.audience,
          httpInterceptor: {
            allowedList: [`${SharedSettings.audience }*`],
          },
        }
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedRequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
