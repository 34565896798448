import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, of } from "rxjs";
import { SharedSettings } from "../setting/shared-settings";
import { map, catchError, concatMap } from "rxjs/operators";
import { UserModel } from "../models/user.model";
import { Router } from "@angular/router";
import { Roles } from "../enums/enums";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  user: UserModel;
  onSpaceChanged = new Subject<any>();
  constructor(private httpClient: HttpClient, private router: Router) {}

  public get currentSpaceId() {
    return sessionStorage.getItem("userSpaceID");
  }

  public set setCurrentSpace(spaceId: string) {
    sessionStorage.setItem("userSpaceID", spaceId);
    localStorage.setItem("userSpaceID", spaceId);
  }

  getUser(): Observable<any> {
    return this.httpClient
      .get(`${SharedSettings.accountServiceUrl }management/users/me`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  getUserSpaces(): Observable<any> {
    return this.httpClient
      .get(`${SharedSettings.accountServiceUrl }spaces`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  changeSpace(spaceId: string, callByParam?: boolean) {
    this.setCurrentSpace = spaceId;
    if (window.location.pathname === "/permission/no-access") {
      this.router.navigateByUrl("dashboards");
    }else if(window.location.search !== "") {
      window.location.href = "/dashboards/portal";
    }else {
      window.location.href = this.router.url;
    }
    this.onSpaceChanged.next(spaceId);
  }

  isOwner(): Observable<boolean> {
    // If current space id already set then directly check user role
    if (this.currentSpaceId) {
      return this.getRoles().pipe(
        map((roles: string[]) => {
          return roles.some((role) => role === Roles.Owner);
        }),
        catchError((error) => {
          throw error;
        })
      );
    }
    // Here space id does not found. So firstly we get spaces and set default space then get user role.
    return this.getUserSpaces().pipe(
      concatMap((spaces) => {
        let minimumLength = 0;
        let initialIndex = 0;

        if (spaces && spaces !== null && spaces.length > minimumLength) {
          this.setCurrentSpace = spaces[initialIndex].id;
          return this.getRoles().pipe(
            map((roles: string[]) => {
              return roles.some((role) => role === Roles.Owner);
            }),
            catchError((error) => {
              throw error;
            })
          );
        }
        return of(false);
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getRoles(): Observable<any> {
    const headers = new HttpHeaders();

    return this.httpClient
      .get(`${SharedSettings.accountServiceUrl }spaces/current/users/me/role`, {
        headers,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  setUser(user) {
    this.user = user;
  }
}
