import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { Observable, Subject, catchError, map, throwError } from "rxjs";
import { IAppConfig } from "./app-config-model";

@Injectable({
  providedIn: "root",
})
export class AppConfig {
  static settings: IAppConfig;
  static config = new Subject();
  constructor(private httpHandler: HttpBackend) {}

  load(): Observable<void> {
    const http: HttpClient = new HttpClient(this.httpHandler);
    const jsonFile = "assets/config/config.json";

    let headers = new HttpHeaders();

    headers = headers.append("NoAuthValidate", "true");

    return http.get<IAppConfig>(jsonFile, { headers }).pipe(
      map((response: IAppConfig) => {
        AppConfig.settings = response;
        AppConfig.config.next(AppConfig.settings);
      }),
      catchError(error => {
        return throwError(() => `Could not load file '${jsonFile}': ${JSON.stringify(error)}`);
      })
    );
  }
}
