export const ErrorCodes = {
  TOOMANYLOGIN: "1020", // Too many users log in at the same time. Please try again in a few minutes. 多くのユーザーが同時にログインしました。数分後にもう一度お試しください。
  NOEMAIL: "1030", // The e-mail address is not yet registered. Please ask your space owner to invite you to Space. メールアドレスが未登録です。 スペースオーナーにスペースへの招待を依頼してください。
  ACCOUNTLINKFAILED: "1040", // Failed to link your account to DIVA Account. Please try again or report this issue to your IT support. お客様のアカウントでDIVAアカウントへのリンクを失敗しました。 もう一度お試しいただくか、この問題をITサポートに報告してください。
  INVALIDDOMAIN: "1050", // The e-mail address does not use external authentication. Please try again with an e-mail that can use external authentication. このメールアドレスは外部認証を利用できません。外部認証を利用できるメールアドレスで再度お試しください。
  SESSIONEXPIRED: "2000", // Your session has expired. Please log in again. セッションの有効期限が切れました。再度ログインしてください。
  ELSE: "9000", // Failed to log in due to an error. Please try again or report this issue to your IT support. エラーによりログインに失敗しました。 もう一度お試しいただくか、この問題をITサポートに報告してください。
  FALLBACK: "0000", // Something went wrong. Please try again or report this issue to your IT support. エラーが発生しました。 もう一度お試しいただくか、この問題をITサポートに報告してください。
  GETUSERFAILED: "3000", // Failed to get user information. Please try again or report this issue to your IT support. ユーザー情報取得は失敗しました。 もう一度お試しいただくか、この問題をITサポートに報告してください。
  BLOCKED: "4000" //  Your account has been blocked. To unblock your account, please contact your IT support. このアカウントはブロックされています。アカウントのブロックを解除するには、ITサポートにお問い合わせください。

};